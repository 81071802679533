import React from 'react'

const AppContext = React.createContext({
        messages: {},
        labels: {},
        titles: {},
        routes: {},
        menus: {},
        userStatusManager: null,
        userPostTypeName: 'user',
        storage: function() {
        },
        template: null,
        style: {},
        lastUrl: '',
        account: {},
        job: {},
        setShouldLeaveWarning: function() {
        },
        getShouldLeaveWarning: function() {
        },
        device: null,
        mouseMove: null
    }
)

export default AppContext

