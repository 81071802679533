import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

import priceWithSpaces from './priceWithSpaces'

export default function getConstants(p = {}) {

    const name = 'order'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Vásárlás',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['continuousShopping' + N + 'Text']: 'Tovább vásárolok',
            ['checkout' + N + 'Text']: 'Kredit vásárlás',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Vásárlások törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('hu-HU')
                } catch (e) {
                }
                return dateText
            },
            [ns + 'SortRelevance']: 'Relevancia',
            [n + 'TotalText']: ({ total }) => (total) ? '' + priceWithSpaces(Math.round(total)) + ' Ft' : '0 Ft',
            [n + 'ProductTitleLabel']: 'Termék',
            [n + 'QuantityLabel']: 'Mennyiség',
            [n + 'TotalTextLabel']: 'Összesen',
            [n + 'CartSummaryLabel']: 'Termékek ára összesen',
            [n + 'NetPriceLabel']: 'Nettó ár',
            [n + 'BrPriceLabel']: 'Bruttó ár',
            [n + 'SummaryLabel']: 'Összesen',

            [n + 'InvoiceData']: 'Számlázási adatok',
            [n + 'InvoiceAddress']: 'Számlázási cím',
            [n + 'ShippingData']: 'Szállítási adatok',
            [n + 'Payments']: 'Fizetések',
            [n + 'Transactions']: 'Kredit változások',
            [n + 'ContentOrderData']: 'Vásárlás adatai',

            [n + 'CartUpdateCartLabel']: 'Kosár frissítése',
            [n + 'SummaryProducts']: 'Kredit csomagok',
            [n + 'OtherFees']: 'Egyéb díjak',

            ['redirectToBarionSubmit']: 'Rendben',
            [n + 'ConnectedJob']: 'Kapcsolt hirdetés',

            [n + 'RequiredCredits']: 'Szükséges kreditek vásárlása',
            [n + 'CreditsWithCurrency']: (credits) => credits ? credits + ' kredit' : '',
            [n + 'MinimumCredits']: 'Minimum vásárolható kredit',

            [n + 'BarionInfoTitle']: 'Bankkártyás fizetés Barion rendszerrel',
            [n + 'BarionInfoDesc']: 'A vásárlás gombra kattintva az oldal átirányítódik a Barion fizető oldalára.',
            [n + 'TotalPriceWithCurrency']: (total) => priceWithSpaces(total) + ' Ft',

            [n + 'EditInvoiceData']: 'Számlázási adatok szerkesztése',

            [n + 'OpenTransactionsSection']: 'Kredit változások mutatása',
            [n + 'CloseTransactionsSection']: 'Bezárás',

            [n + 'OpenPaymentsSection']: 'Fizetések mutatása',
            [n + 'ClosePaymentsSection']: 'Bezárás',

            [n + 'SearchLabel']: 'Keresés vásárlás azonosító alapján',
            [n + 'SearchLabelForAdmin']: 'Vásárlás azonosító, adószám, vagy felhasználó szókeresés',

            [n + 'InfoTitle']: 'Álláshirdetés feladása, aktiválása, információk',
            [n + 'InfoCloseButton']: 'Bezár'

        },
        menus: {
            ...menus,
            ['user' + Ns + 'MainMenu']: 'Kredit vásárlások',
            ['my' + Ns + 'MainMenu']: 'Kredit vásárlások',
            ['user' + Ns + 'Menu']: 'Vásárlások',
            ['user' + Ns + 'UnpaidMenu']: 'Nem fizetett vásárlások',
            ['my' + Ns + 'Menu']: 'Vásárlásaim',
            ['new' + N + 'Menu']: 'Kredit vásárlás',
            [ns + 'Menu']: 'Vásárlások',
            ['deleted' + Ns + 'Menu']: 'Törölt vásárlások',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt vásárlások',
            ['protection' + Ns + 'Menu']: 'Védett vásárlások',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'A vásárlás mentésre került',
            ['save' + N + 'SuccessMessage']: 'A vásárlás mentésre került',
            ['delete' + N + 'Success']: 'A vásárlás törölve lett',
            ['delete' + Ns + 'Success']: 'A vásárlások törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli a vásárlást?',
            ['delete' + Ns + 'Question']: 'Biztosan törli a vásárlásokat?',
            ['ban' + N + 'Success']: 'A vásárlás véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy a vásárlást véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A vásárlás jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod a vásárlást',
            ['protection' + N + 'Success']: 'A vásárlás védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd a vásárlást?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek vásárlások',
            [n + 'EmptyCart']: 'Válassz egy kredit csomagot',
            [n + 'CartQuestion']: 'Mennyi kreditet szeretnél vásárolni?',

            ['redirectToBarionDialogMessage']: 'Átirányítjuk a Barion oldalára, kérjük várjon...',
            [n + 'NewPaymentInfoInOrderContent']: ({ lastPayment }) => {
                if (lastPayment?.paymentStatus === 20) {
                    return `A Barion tranzakció elindult, de még nem teljesült. Szeretnéd újraindítani a fizetést?`
                } else if (lastPayment?.paymentStatus === 10) {
                    return `A Barion tranzakció elutasítva, szeretnéd újraindítani a fizetést?`
                }
            },
            [n + 'ValidationInvoiceAddress']: 'Ebben a mezőben nem szerepelhet a város és az irányítószám',
            [n + 'InfoContent']: 'Fizetés előtt olvasd el a tájékoztatót!',
            [name + 'OnlinePaymentError']: 'Elnézését kérjük, a Barion rendszerében átmenetileg fennakadások tapasztalhatóak, kérjük próbálja meg később'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Vásárlás',
            [ns + 'Title']: 'Vásárlások',
            ['new' + N + 'Title']: 'Kredit vásárlás',
            ['edit' + N + 'Title']: 'Vásárlás szerkesztése',
            ['user' + Ns + 'Title']: 'Vásárlások',
            ['user' + Ns + 'UnpaidTitle']: 'Nem fizetett vásárlások',
            ['userDeleted' + Ns + 'Title']: 'Törölt vásárlások',
            ['my' + Ns + 'Title']: 'Vásárlásaim',
            ['myDeleted' + Ns + 'Title']: 'Törölt vásárlásaim',

            ['dialogDelete' + N + 'Title']: 'Vásárlás törlése',
            ['dialogBan' + N + 'Title']: 'Vásárlás törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Vásárlás jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Vásárlás védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Vásárlás védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Vásárlások törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt vásárlások',
            ['deleted' + Ns + 'Title']: 'Törölt vásárlások',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett vásárlások',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Vásárlások kiválasztása',
            ['select' + N]: 'Vásárlás kiválasztása',

            [n + 'ShippingData']: 'Szállítási adatok',
            [n + 'InvoiceData']: 'Számlázási adatok',

            ['redirectToBarionDialogTitle']: 'Átirányítás',
            [name + 'OnlinePaymentError']: 'Barion hiba'
        }
    }
}
