import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const { name = 'referenceLetter' } = p

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Mentés',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Ajánlólevelek törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',

            [ns + 'SortRelevance']: 'Relevancia',

            ['cancelAbortUpload' + Ns + 'Text']: 'Mégsem',
            ['abortUpload' + Ns + 'Text']: 'Megszakítás',
            ['upload' + Ns + 'HelperText']: 'Kattints vagy húzd ide az ajánlóleveleket',
            ['upload' + N + 'HelperText']: 'Kattints vagy húzd ide az ajánlólevelet',
            ['download' + N]: 'Ajánlólevél letöltése',

            [ns + 'OrSelectFromListOpenLabel']: 'Médiatár',
            [n + 'ListInfoTitle']: ''
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Ajánlólevelek',
            ['my' + Ns + 'Menu']: 'Ajánlóleveleim',
            ['new' + N + 'Menu']: 'Ajánlólevelek feltöltése',
            [ns + 'Menu']: 'Ajánlólevelek',
            ['deleted' + Ns + 'Menu']: 'Törölt ajánlólevelek',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt ajánlólevelek',
            ['protection' + Ns + 'Menu']: 'Védett ajánlólevelek',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + Ns + 'SuccessMessage']: 'Ajánlólevelek feltöltve',
            ['save' + N + 'SuccessMessage']: 'Az ajánlólevél mentésre került',
            ['delete' + N + 'Success']: 'Az ajánlólevél törölve lett',
            ['delete' + Ns + 'Success']: 'Az ajánlólevelek törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli az ajánlólevelet?',
            ['delete' + Ns + 'Question']: 'Biztosan törli az ajánlóleveleket?',
            ['ban' + N + 'Success']: 'Az ajánlólevél véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy az ajánlólevelet véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'Az ajánlólevél jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod az ajánlólevelet',
            ['protection' + N + 'Success']: 'Az ajánlólevél védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölödrajzot?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek ajánlólevelek',

            ['abortUpload' + Ns + 'Question']: 'Megszkítod a feltöltést?',
            ['abortUpload' + Ns + 'Success']: 'Feltöltés megszakítva',
            ['new' + Ns + 'ClientErrorMessage']: 'Hiba történt az ajánlólevél megnyitásakor, próbáld újratölteni az oldalt',
            [n + 'ListInfoContent']: 'Ide csak az ajánlóleveledet töltsd fel. Ha nincs, kérhetsz a volt munkáltatódtól. Az önéletrajzodat ne itt töltsd fel. Ebben a listában a már előzőleg feltöltött ajánlóleveleidet láthatod, és átírhatod a fileok nevét. Ha új ajálnólevelet töltesz fel, az bekerül ide a médiatárba, de a munkavállalói profilodban nem változik meg.'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Ajánlólevél',
            [ns + 'Title']: 'Ajánlólevelek',
            ['new' + N + 'Title']: 'Új ajánlólevél',
            ['edit' + N + 'Title']: 'Ajánlólevél szerkesztése',
            ['user' + Ns + 'Title']: 'Ajánlólevelek',
            ['userDeleted' + Ns + 'Title']: 'Törölt ajánlólevelek',
            ['userBanned' + Ns + 'Title']: 'Véglegesen törölt ajánlólevelek',
            ['my' + Ns + 'Title']: 'Ajánlóleveleim',
            ['myDeleted' + Ns + 'Title']: 'Törölt ajánlóleveleim',
            ['myBanned' + Ns + 'Title']: 'Véglegesen törölt ajánlóleveleim',

            ['dialogDelete' + N + 'Title']: 'Ajánlólevél törlése',
            ['dialogBan' + N + 'Title']: 'Ajánlólevél törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Ajánlólevél jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Ajánlólevél védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Ajánlólevél védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Ajánlólevelek törlése',
            ['dialogAbortUpload' + Ns + 'Title']: 'Feltöltés megszakítása',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt ajánlólevelek',
            ['deleted' + Ns + 'Title']: 'Törölt ajánlólevelek',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett ajánlólevelek',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Ajánlólevelek kiválasztása',
            ['select' + N]: 'Ajánlólevél kiválasztása'
        }
    }
}
