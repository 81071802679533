import React, { useRef } from 'react'

import { defaultGetPageName } from '../../components/Post/utils'

import TrashIcon from 'vlm-ui/dist/common/src/svg/TrashIcon'
import RestoreIcon from 'vlm-ui/dist/common/src/svg/RestoreIcon'
import Menu from 'vlm-ui/dist/common/src/components/Menu'

import capitalize from '../../utils/capitalize'
import defaultGetMenu, { getMenuProps as defaultGetMenuProps } from '../../components/Post/menu'
import { getPostOrRequest, updateCache } from '../../components/Post/Edit/updateCache'

import defaultPostTypeConfig from '../post'
import getConstants from './constants'

import Content from './Content'
import New from './New'
import Edit from './Edit'

import AppliesForMeListTools from './AppliesForMeListTools'
import PostInDrawer from '../../components/Post/PostInDrawer'

import { CustomMenuItem } from '../../components/Posts/Components/CustomMenuItem'
import defaultGetStatus from '../../utils/getStatus'

//import PostContext from '../../components/Post/context'
//import Posts from '../page/Content/Posts'
import HumanSearchIcon from 'vlm-ui/dist/common/src/svg/HumanSearchIcon'

import { getProfessionMenu } from '../../components/App/getProfessionMenu'

function getMenuForJobAuthor(props) {

    const { appContext, name = 'apply' } = props
    const n = name

    return [
        {
            label: appContext.labels[n + 'MarkIrrelevant'],
            role: (p) => {
                const jobAuthor = p.post?._jobAuthor
                return (p.user?._id && p.user?._id === jobAuthor && !p.post._markedIrrelevant)
            },
            featured: true,
            onlyIcon: true,
            startIcon: <TrashIcon />,
            onClick: function(e) {
                e.preventDefault()
                e.stopPropagation()
                if (props.onMarkIrrelevant) {
                    props.onMarkIrrelevant(true)
                }
            }
        },
        {
            label: appContext.labels[n + 'RemoveMarkIrrelevant'],
            role: (p) => {
                const jobAuthor = p.post?._jobAuthor
                return (p.user?._id && p.user?._id === jobAuthor && p.post._markedIrrelevant)
            },
            featured: true,
            onlyIcon: true,
            startIcon: <RestoreIcon />,
            onClick: function(e) {
                e.preventDefault()
                e.stopPropagation()
                if (props.onMarkIrrelevant) {
                    props.onMarkIrrelevant(false)
                }
            }
        }
    ]

}

async function updateApplyCache(props) {

    const { name = 'apply', wapp, req, res, /*postsRef,*/ callback } = props
    const _post = props.post

    const post = await getPostOrRequest({ wapp, req, res, post: _post, name })
    if (post?._status) {
        updateCache({ wapp, res, post })

        /*if (postsRef.actions) {
            const posts = postsRef.actions.getPosts()

            let shouldUpdate = false;
            if (posts.length) {
                posts.forEach((p, i) => {
                    if (p._id === post._id) {
                        posts[i] = { ...post }
                        shouldUpdate = true;
                    }
                })
            }
            if (shouldUpdate) {
                await postsRef.actions.setPosts(posts)
            }
        }*/

        if (callback) {
            await callback({ ...props, post })
        }
    }

}

function getMenuPropsForJobAuthor(props) {

    const { wapp, req, res, name = 'apply', /*postsRef = {}, */appContext, onSubmit } = props
    const n = name

    return {
        onMarkIrrelevant: async (irrelevant = false) => {
            let response = await wapp.requests.send({
                requestName: n + 'MarkIrrelevant',
                args: {
                    _id: props.post?._id,
                    irrelevant
                },
                req,
                res
            })
            if (response && response[n + 'MarkIrrelevant']) {
                response = response[n + 'MarkIrrelevant']
            }
            if (response.record?._id) {

                await updateApplyCache({
                    name,
                    wapp,
                    req,
                    res,
                    //postsRef,
                    post: response.record,
                    callback: async ({ post }) => {
                        await appContext.template.current?.actions?.setSnackMessage(
                            post._markedIrrelevant ?
                                appContext.messages[n + 'MarkIrrelevantSuccessMessage'] :
                                appContext.messages[n + 'RemoveMarkIrrelevantSuccessMessage']
                        )
                    }
                })

                if (onSubmit) {

                    const iOs = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
                    if (iOs) {
                        const scrollElement = appContext.template.current.actions.getScrollElement()
                        if (scrollElement) {
                            scrollElement.style.minHeight = scrollElement.offsetHeight + 'px'
                        }
                    }

                    await onSubmit(null, response, name + 'MarkIrrelevant')

                    if (iOs) {
                        setTimeout(() => {
                            const scrollElement = appContext.template.current.actions.getScrollElement()
                            if (scrollElement) {
                                scrollElement.style.minHeight = null
                            }
                        }, 100)
                    }
                }

            }
        }
    }
}

export function getClearCacheForJob({ wapp, req, res }) {
    return async function clearCache(e, response) {
        if (response?.record?.job) {
            const post = await getPostOrRequest({ wapp, req, res, post: response.record.job, name: 'job' })
            if (post?._status) {
                updateCache({ wapp, res, post })
            }
        }
    }
}

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    job: { type: Object },
                    employeeCv: { type: Object }
                },
                ...(p.config) ? p.config : {}
            }
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {
        const {
            wapp,
            urlParams = [
                '/page/:pagination',
                '/sort/:sort',
                '/limit/:limit',
                '/professions/:professions'
            ]
        } = p
        const name = 'apply'

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name,
            urlParams
        })

        wapp.contents.add({
            [name]: {
                ...wapp.contents.get(name),
                robots: () => {
                    return 'noindex,nofollow'
                }
            }
        })
        return r
    },
    requestForUserPage: async function requestForUserPage(p = {}) {

        const { req, res, name = 'apply' } = p

        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const wappResponse = res.wappResponse
        const wappRequest = req.wappRequest
        const query = wappRequest.query
        const route = wappResponse.route
        const { params } = route
        const { _id, page, pageType } = params

        const isForMe = (page === ns && (pageType === 'forme' || pageType === 'formedeleted' || pageType === 'formebanned') && _id)

        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name,
            ...isForMe ? {
                pageType: '',
                requestName: name + 'FindManyJobAuthor',
                transformRequestParams: ({ requestParams }) => {
                    if (query.job) {
                        requestParams.args.filter.job = query.job
                    }
                    if (pageType === 'forme') {
                        requestParams.args.filter._markedIrrelevant = false
                    }
                    if (pageType === 'formedeleted') {
                        requestParams.args.filter._markedIrrelevant = true
                    }
                    if (pageType === 'formebanned') {
                        requestParams.args.filter._operators._status.gt = 19
                        requestParams.args.filter._operators._status.lt = 40
                    }

                    return requestParams
                }
            } : {}
        })
    },
    getComponent: function(p) {

        const name = 'apply'
        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const { context, appContext } = p
        const { routes /*userStatusManager*/ } = appContext

        const { wapp, res, req } = context
        const route = res.wappResponse.route
        const requestPath = route.requestPath
        const { page } = route.params
        const user = req.wappRequest.user

        if ((requestPath.startsWith(routes[name + 'Route'])) || (requestPath.startsWith(routes[ns + 'Route']))) {

            const isAdmin = user && user._status_isFeatured

            const clearCacheForJob = getClearCacheForJob({ wapp, req, res })

            const pages = {
                content: Content,
                new: New,
                edit: Edit
            }

            const getPageName = ({ page, ...rest }) => {
                return defaultGetPageName({ page, ...rest })
            }

            if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
                return null
            }

            const requestKeys = [name + 'FindById']
            const post = requestKeys.map((requestName) => res.wappResponse.store.getState('res.responses.' + requestName)).find((r) => r?._id)

            if (requestPath.startsWith(routes[name + 'Route']) && !post?._author) {
                return null
            }

            //const isAuthor = ((user?._id && user._id.toString() === post?._author) || (user?._id && user._id.toString() === post?._author?._id))
            //const isAuthorOrAdmin = (isAuthor || isAdmin)

            if (
                (requestPath.startsWith(routes[name + 'Route']) && requestPath === routes[name + 'Route'] + '/new') ||
                (requestPath.startsWith(routes[name + 'Route']) && page && !isAdmin)
            ) {
                return null
            }

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                componentProps: {
                    getMenuProps: (props) => {
                        const onSubmit = props.onSubmit
                        return {
                            ...defaultGetMenuProps({
                                ...props,
                                onSubmit: async (...attr) => {
                                    await clearCacheForJob(...attr)
                                    if (onSubmit) {
                                        await onSubmit(...attr)
                                    }
                                }
                            }),
                            ...getMenuPropsForJobAuthor({ ...props, wapp, req, res, name, onSubmit })
                        }
                    },
                    getMenu: (props) => {
                        const menu = defaultGetMenu(props)
                        menu[0].role = (p) => {
                            return (p.user && p.user._status_isFeatured && !p.post._status_isBanned) && (p.post?._id && p.page !== 'edit' && (p.page === 'content' || !p.page))
                        }
                        return [
                            ...getMenuForJobAuthor(props),
                            ...menu
                        ]
                    },
                    pages,
                    getPageName,
                    showAuthor: () => false,
                    getProps: ({ pageName }) => {
                        if (pageName === 'content') {
                            return {
                                PaperComponentProps: {
                                    color: undefined,
                                    elevation: 0,
                                    style: {
                                        padding: 0
                                    }
                                },
                                getTitle: ({ post }) => post._title
                                //disableTitleContainer: true
                            }
                        }
                        return {}
                    },
                    getStatus: (props) => {
                        const { appContext, post, user } = props
                        const N = capitalize(name)
                        const jobAuthor = post?._jobAuthor
                        const isJobAuthor = (user?._id && user?._id === jobAuthor)
                        if (isJobAuthor) {
                            if (post?._markedIrrelevant) {
                                return appContext.titles['statusDeleted' + N + 'Title']
                            }
                        }
                        return defaultGetStatus(props)
                    }
                },
                archiveComponentProps: {
                    selectable: false,
                    getMenuProps: (props) => {
                        const onSubmit = props.onSubmit
                        return defaultGetMenuProps({
                            ...props,
                            onSubmit: async (...attr) => {
                                await clearCacheForJob(...attr)
                                if (onSubmit) {
                                    await onSubmit(...attr)
                                }
                            }
                        })
                    },
                    getMenu: (props) => {
                        const menu = defaultGetMenu(props)
                        menu[0].role = (p) => {
                            return p.user && p.user._status_isFeatured && !p.post._status_isBanned
                        }
                        menu[3].role = () => {
                            return false
                        }
                        menu[4].role = () => {
                            return false
                        }
                        return menu
                    },
                    type: 'list',
                    beforeSetPosts: ({ posts }) => {
                        return posts?.map((post) => {
                            return {
                                ...post,
                                _author: {
                                    ...post._author?._id ? post._author : {},
                                    title: post.employeeName || post._author.title
                                }
                            }
                        }) || []
                    },
                    MenuItemComponent: (props) => {
                        return <CustomMenuItem
                            {...props}
                            {...props.itemProps.post.paperColor ? { color: props.itemProps.post.paperColor } : {}}
                        />
                    }
                }
            })
        }
    },
    userPageFunctions: function(p) {

        const { context, appContext, postContext } = p

        const n = 'apply'
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'
        const N = capitalize(n)
        const Ns = capitalize(ns)

        const { wapp, req, res } = context

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { _id, page, pageType } = params

        const isForMe = (page === ns && (pageType === 'forme' || pageType === 'formedeleted' || pageType === 'formebanned') && _id)

        const clearCacheForJob = getClearCacheForJob({ wapp, req, res })
        //const postsRef = {}
        const postInDrawer = {}

        function removeApplyUrl(url = window.location.href) {
            try {
                const fullUrl = new URL(url)
                fullUrl.searchParams.delete(n)
                return fullUrl.href.split(fullUrl.origin)[1]
            } catch (e) {
            }
        }

        function getApplyUrl(post, url = window.location.href) {
            try {
                const fullUrl = new URL(url)
                fullUrl.searchParams.delete(n)
                fullUrl.searchParams.append(n, post._id)
                return fullUrl.href.split(fullUrl.origin)[1]
            } catch (e) {
            }
        }

        function beforeSetUrl(url) {
            try {
                const hostname = req.wappRequest.hostname
                const protocol = req.wappRequest.protocol
                const fullUrl = new URL(protocol + '://' + hostname + url)
                fullUrl.searchParams.delete('apply')
                return fullUrl.href.split(fullUrl.origin)[1]
            } catch (e) {
            }
            return url
        }

        let startUrl
        let _onSubmit

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p,
            name: n,
            nsPageProps: {
                selectable: false,
                type: 'list',
                disableAvatars: false,
                getMenu: (props) => {
                    const menu = defaultGetMenu(props)
                    menu[0].role = (p) => {
                        return p.user && p.user._status_isFeatured && !p.post._status_isBanned
                    }
                    menu[3].role = () => {
                        return false
                    }
                    menu[4].role = () => {
                        return false
                    }
                    return [
                        ...isForMe ? [
                            ...getMenuForJobAuthor(props)
                        ] : [],
                        ...menu
                    ]
                },
                getMenuProps: (props) => {
                    const onSubmit = props.onSubmit
                    _onSubmit = onSubmit
                    return {
                        ...defaultGetMenuProps({
                            ...props,
                            onSubmit: async (...attr) => {
                                await clearCacheForJob(...attr)
                                if (onSubmit) {
                                    await onSubmit(...attr)
                                }
                            }
                        }),
                        ...isForMe ? getMenuPropsForJobAuthor({
                            ...props,
                            wapp,
                            req,
                            res,
                            name: n, /*postsRef, */
                            onSubmit
                        }) : {}
                    }
                },
                MenuItemComponent: (props) => {
                    return <CustomMenuItem
                        {...props}
                        {...props.itemProps.post.paperColor ? { color: props.itemProps.post.paperColor } : {}}
                        AvatarProps={{
                            href: null
                        }}
                    />
                },
                tableProps: {
                    enableTips: true
                },
                ...isForMe ? {
                    requestName: n + 'FindManyJobAuthor',
                    beforeSetPosts: ({ posts }) => {
                        return (posts?.map((post) => {
                            return {
                                ...post,
                                _author: {
                                    ...post._author?._id ? post._author : {},
                                    title: post.employeeName || post._author.title
                                }
                            }
                        }) || []).filter((post) => {
                            return (pageType === 'formedeleted') ? post._markedIrrelevant : !post._markedIrrelevant
                        })
                    },
                    onClick: async (e, post) => {
                        if (postInDrawer.actions) {
                            e.preventDefault()
                            e.stopPropagation()
                            const newUrl = getApplyUrl(post)
                            window.history.replaceState(window.history.state, undefined, newUrl)
                            await postInDrawer.actions.setPostId(post._id)
                        }
                    },
                    /*effect: ({actions})=>{
                        postsRef.actions = actions
                    },*/
                    BeforePostsComponent: () => {

                        let shouldUpdate = useRef(false)

                        return (
                            <>
                                <AppliesForMeListTools />
                                <PostInDrawer
                                    name={n}
                                    effect={({ actions }) => {
                                        postInDrawer.actions = actions
                                    }}
                                    onOpen={async () => {
                                        startUrl = window.location.href
                                    }}
                                    beforeClose={() => {
                                        const newUrl = removeApplyUrl(startUrl)
                                        const scrollTop = appContext.template.current.actions.getScrollTop()
                                        window.history.replaceState(window.history.state, undefined, newUrl)
                                        appContext.template.current.actions.setScrollTop(scrollTop)
                                    }}
                                    onClose={async () => {
                                        if (shouldUpdate.current) {

                                            shouldUpdate.current = false

                                            const _post = postInDrawer.actions?.getPost()

                                            if (_post?._id) {
                                                const requestKeys = [n + 'FindById']
                                                const post = requestKeys.map((requestName) => res.wappResponse.store.getState('res.responses.' + requestName)).find((r) => r?._id)

                                                if (_onSubmit) {

                                                    const iOs = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
                                                    if (iOs) {
                                                        const scrollElement = appContext.template.current.actions.getScrollElement()
                                                        if (scrollElement) {
                                                            scrollElement.style.minHeight = scrollElement.offsetHeight + 'px'
                                                        }
                                                    }

                                                    await _onSubmit(null, post?._id ? post : _post, n + 'CloseDrawer')

                                                    if (iOs) {
                                                        setTimeout(() => {
                                                            const scrollElement = appContext.template.current.actions.getScrollElement()
                                                            if (scrollElement) {
                                                                scrollElement.style.minHeight = null
                                                            }
                                                        }, 100)
                                                    }

                                                }
                                            }
                                        }
                                    }}
                                    PostTypesComponentProps={{
                                        propsForGetMenuProps: {
                                            onSubmit: () => {
                                                shouldUpdate.current = true
                                            }
                                        },
                                        pageProps: {
                                            onChange: () => {
                                                shouldUpdate.current = true
                                            }
                                        }
                                    }}
                                />
                            </>
                        )
                    },
                    PaginationProps: {
                        beforeSetUrl
                    },
                    ListToolsProps: {
                        beforeSetUrl,
                        SearchProps: {
                            beforeSetUrl
                        }
                    }
                } : {},
                ...p.nsPageProps ? p.nsPageProps : {}
            }
        })

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const { appContext /*statusManager*/ } = props
            const { menus, routes /*userStatusManager*/ } = appContext

            return [
                {
                    label: function(/*p*/) {
                        //const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return menus[ns + 'Menu']
                    },
                    role: function(p) {
                        const viewerIsAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        const viewerIsAdmin = (p.user?._id && p.user._status_isFeatured)
                        return (viewerIsAuthor || viewerIsAdmin)
                    },
                    startIcon: <HumanSearchIcon />,
                    order: 30,
                    items: [
                        {
                            label: menus['new' + N + 'Menu'],
                            href: routes[n + 'Route'] + '/new',
                            role: function(/*p*/) {
                                //return ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return false
                            },
                            disableParentRoute: true
                        },
                        {
                            label: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAuthor) ? menus['my' + Ns + 'ForMeMenu'] : menus[postContext.name + Ns + 'ForMeMenu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/forme' : routes[postContext.name + Ns + 'Route'] + '/forme'
                            },
                            role: function(p) {
                                const isAdmin = p.user && p.user._status_isFeatured
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAdmin || isAuthor)
                            }
                        },
                        {
                            label: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] : routes[postContext.name + Ns + 'Route']
                            },
                            role: function(p) {
                                const isAdmin = p.user && p.user._status_isFeatured
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAdmin || isAuthor)
                            }
                        },
                        {
                            divider: true,
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        },
                        {
                            label: function() {
                                return menus['deleted' + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/deleted' : routes[postContext.name + Ns + 'Route'] + '/deleted'
                            },
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        },
                        {
                            label: function() {
                                return menus['banned' + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/banned' : routes[postContext.name + Ns + 'Route'] + '/banned'
                            },
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        }
                    ]
                }
            ]
        }

        function addContentMenuItems() {
            return []
        }

        function getPageName({ user, post, page /*statusManager*/ }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)

            return (
                (page === ns && !pageType) ||
                (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                (page === ns && pageType === 'forme' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'formedeleted' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'formebanned' && isAdmin) ||
                (page === ns && pageType === 'deleted' && isAdmin) ||
                (page === ns && pageType === 'banned' && isAdmin)
            ) ? (isAuthorOrAdmin) ? page : 'notFound' : null

        }

        function getDashBoardTitle({ user, post, page }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)

            if (page === ns && pageType === 'deleted' && !isAdmin) {
                return null
            }

            if (page === ns && pageType === 'forme' && isAuthorOrAdmin) {
                return (isAuthor) ? appContext.titles['my' + Ns + 'ForMeTitle'] : appContext.titles[postContext.name + Ns + 'ForMeTitle']
            }

            if (page === ns && pageType === 'formedeleted' && isAuthorOrAdmin) {
                return (isAuthor) ? appContext.titles['my' + Ns + 'ForMeDeletedTitle'] : appContext.titles[postContext.name + Ns + 'ForMeDeletedTitle']
            }

            if (page === ns && pageType === 'formebanned' && isAuthorOrAdmin) {
                return appContext.titles[postContext.name + Ns + 'ForMeBannedTitle']
            }

            return r.getDashBoardTitle({ user, post, page })

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName,
            getDashBoardTitle
        }
    },
    adminMenu: function(p) {
        const name = 'apply'

        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
        defaultAdminMenu.order = 30

        const { routes } = p.appContext

        const filtered = []

        const appliesMenu = getProfessionMenu({
            parentRoute: routes.appliesRoute,
            utils: p.utils,
            appContext: p.appContext,
            name
        })
        const appliesBannedMenu = getProfessionMenu({
            parentRoute: routes.appliesRoute + '/banned',
            utils: p.utils,
            appContext: p.appContext,
            name
        })

        defaultAdminMenu.items = defaultAdminMenu.items.filter((item, i) => {

            if (i === 3 || i === 5 || i === 6 || i === 7) {
                filtered.push(item)
            }

            return (
                item.href !== routes[name + 'Route'] + '/new' &&
                filtered.indexOf(item) === -1)
        })

        defaultAdminMenu.items[0].items = [
            {
                ...defaultAdminMenu.items[0],
                label: p.appContext.labels[name + 'AllLabel']
            },
            ...appliesMenu
        ]

        delete defaultAdminMenu.items[0].href

        defaultAdminMenu.items[2].items = [
            {
                ...defaultAdminMenu.items[2],
                label: p.appContext.labels[name + 'AllLabel']
            },
            ...appliesBannedMenu
        ]

        delete defaultAdminMenu.items[2].href

        defaultAdminMenu.items.push({
            label: p.appContext.labels[name + 'OtherFilters'],
            items: [
                ...filtered
            ]
        })

        return defaultAdminMenu
    }
}

export default postTypeConfig
